import { FC, useCallback, useState } from 'react'
import Link from 'next/link'
import { router } from '@lifedot/router'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'
import { transition } from '@lifedot/styles/transition'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Condition } from '@lifedot/constants/searchChoices'
import { PrefectureRomas } from '@lifedot/constants/prefectures'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const areaStyles = {
  areaName: css({
    fontWeight: 'bold',
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
    background: 'none',
    [mq('sp')]: {
      width: '100%',
      padding: 8,
      cursor: 'pointer'
    }
  }),
  pref: css({
    display: 'flex',
    [mq('sp')]: {
      maxHeight: 0,
      overflow: 'hidden',
      flexDirection: 'column',
      transition: transition('easeOut')
    }
  }),
  open: css({
    [mq('sp')]: {
      maxHeight: 999
    }
  }),
  prefName: css({
    marginRight: 12,
    [mq('sp')]: {
      borderTop: `1px solid ${palette.gray1}`,
      margin: 0
    }
  }),
  link: css({
    display: 'block',
    [mq('sp')]: {
      padding: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  }),
  icon: css({
    visibility: 'hidden',
    position: 'absolute',
    [mq('sp')]: {
      visibility: 'visible',
      position: 'relative'
    }
  }),
  openIcon: css({
    transform: 'rotate(180deg)'
  }),
  disabled: css({
    color: palette.gray5,
    display: 'block',
    [mq('sp')]: {
      padding: 8
    }
  })
}

export interface PrefectureLinksProps {
  area: string
  prefectures: { roma: PrefectureRomas; name: string }[]
  condition?: Condition
  disables?: Record<PrefectureRomas, boolean>
  isShop?: boolean
}

export const PrefectureLinks: FC<PrefectureLinksProps> = ({
  area,
  prefectures,
  condition,
  disables,
  isShop = false
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = useCallback(() => setIsOpen((prev) => !prev), [])

  return (
    <>
      <button css={areaStyles.areaName} onClick={handleOpen}>
        {area}
        <ExpandMoreIcon
          css={[areaStyles.icon, isOpen && areaStyles.openIcon]}
        />
      </button>
      <ul css={[areaStyles.pref, isOpen && areaStyles.open]}>
        {prefectures.map(({ roma, name }) => (
          <li css={areaStyles.prefName} key={roma}>
            {!disables?.[roma] ? (
              <Link
                href={
                  isShop
                    ? router.shopList({ prefectureRoma: roma })
                    : router.list({ prefectureRoma: roma, condition })
                }
                passHref
                prefetch={false}
              >
                <a css={areaStyles.link}>
                  {name}
                  <ChevronRightIcon fontSize="small" css={areaStyles.icon} />
                </a>
              </Link>
            ) : (
              <span css={areaStyles.disabled}>{name}</span>
            )}
          </li>
        ))}
      </ul>
    </>
  )
}
