import { FC } from 'react'
import {
  noSuffixPrefectures as prefectures,
  prefecturesByRegion
} from '@lifedot/constants/prefectures'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'
import { elevation } from '@lifedot/styles/elevation'
import { PrefectureLinks, PrefectureLinksProps } from './PrefectureLinks'

const styles = {
  area: css({
    display: 'flex',
    ':not(:first-of-type)': {
      marginTop: 16
    },
    [mq('sp')]: {
      flexDirection: 'column',
      width: '100%',
      border: `1px solid ${palette.gray1}`,
      borderRadius: 4,
      boxShadow: elevation[1],
      ':not(:first-of-type)': {
        marginTop: 8
      }
    }
  })
}

export const PrefectureLinksList: FC<
  Pick<PrefectureLinksProps, 'condition' | 'disables' | 'isShop'>
> = (props) => {
  return (
    <ul>
      {areas.map(({ label, prefectures }) => (
        <li css={styles.area} key={label}>
          <PrefectureLinks area={label} prefectures={prefectures} {...props} />
        </li>
      ))}
    </ul>
  )
}

const areas = [
  {
    label: '北海道・東北',
    prefectures: [
      ...prefecturesByRegion.hokkaido,
      ...prefecturesByRegion.tohoku
    ].map((prefecture) => ({ roma: prefecture, name: prefectures[prefecture] }))
  },
  {
    label: '関東',
    prefectures: prefecturesByRegion.kanto.map((prefecture) => ({
      roma: prefecture,
      name: prefectures[prefecture]
    }))
  },
  {
    label: '東海',
    prefectures: prefecturesByRegion.tokai.map((prefecture) => ({
      roma: prefecture,
      name: prefectures[prefecture]
    }))
  },
  {
    label: '北陸・甲信越',
    prefectures: [
      ...prefecturesByRegion.hokuriku,
      ...prefecturesByRegion.koushinetsu
    ].map((prefecture) => ({ roma: prefecture, name: prefectures[prefecture] }))
  },
  {
    label: '関西',
    prefectures: prefecturesByRegion.kansai.map((prefecture) => ({
      roma: prefecture,
      name: prefectures[prefecture]
    }))
  },
  {
    label: '中国・四国',
    prefectures: [
      ...prefecturesByRegion.chugoku,
      ...prefecturesByRegion.shikoku
    ].map((prefecture) => ({ roma: prefecture, name: prefectures[prefecture] }))
  },
  {
    label: '九州・沖縄',
    prefectures: prefecturesByRegion.kyushu.map((prefecture) => ({
      roma: prefecture,
      name: prefectures[prefecture]
    }))
  }
]
